<template>
  <div class="about-page">

    <div class="page__transition-1 content--transition">
      <div class="universal__title">
        <div class="universal__title_back h1">{{ $t('about.aboutBack') }}</div>
        <div class="universal__title_main">
          <div class="universal__title_main-text h3">{{ $t('about.aboutTitle') }}</div>
          <div class="universal__title_main-subtext h3">{{ $t('about.aboutsubTitle') }}</div>
        </div>
      </div>
      <div class="universal__content">
        <div class="universal__content_col">
          <p class="h4">
            {{ $t('about.aboutText1') }}
          </p>
          <p class="h4">
            {{ $t('about.aboutText2') }}
          </p>
        </div>

        <div class="universal__content_col">
          <p class="h4">
            {{ $t('about.aboutText3') }}
          </p>
          <p class="h4">
            {{ $t('about.aboutText4') }}
          </p>
          <p class="h4">
            {{ $t('about.aboutText5') }}
          </p>
        </div>
      </div>
    </div>

    <div class="page__transition-2">
      <div class="about-page__slider" >
        <hooper :settings="hooperSettings">
          <slide class="about-page__slide">
            <div class="about-page__cont">
              <div class="about-page__blocks_block">
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-1-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-1') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-1') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-1') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-1') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
              <div class="about-page__blocks_block">
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-3-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-3') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-3') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-3') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-3') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
              <div class="about-page__blocks_block">
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-7-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-7') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-7') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-7') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-7') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
              <div class="about-page__blocks_block">
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-5-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-5') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-5') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-5') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-5') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
              <div class="about-page__blocks_block">
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-8-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-8') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-8') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-8') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-8') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-10-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-10') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-10') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-10') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-10') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
              <div class="about-page__blocks_block">
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-14-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-14') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-14') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-14') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-14') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-15-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-15') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-15') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-15') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-15') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-19-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-19') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-19') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-19') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-19') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-23-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-23') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-23') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-23') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-23') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-22-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-22') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-22') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-22') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-22') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-20-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-20') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-20') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-20') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-20') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-32-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-32') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-32') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-32') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-32') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-26-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-26') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-26') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-26') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-26') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-27-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-27') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-27') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-27') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-27') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-30-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-30') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-30') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-30') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-30') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-33-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-33') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-33') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-33') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-33') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-34-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-34') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-34') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-34') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-34') }}</div>
              </div>
            </div>
          </slide>

          <slide class="about-page__slide">
            <div class="about-page__cont">
            <div class="about-page__blocks_block" >
                <div class="about-page__blocks_hover-bg" :style="{ 'background-image': 'url(' + require('../assets/img/people/people-35-min.png') + ')' }"></div>
                <div class="about-page__blocks_hover">
                  <div class="about-page__blocks_hover-name h3">{{ $t('about.aboutName-35') }}</div>
                  <div class="about-page__blocks_hover-position">{{ $t('about.aboutPosition-35') }}</div>
                </div>
              </div>
              <div class="about-page__slide_notes">
                <div class="about-page__slide_notes-name h3">{{ $t('about.aboutName-35') }}</div>
                <div class="about-page__slide_notes-position">{{ $t('about.aboutPosition-35') }}</div>
              </div>
            </div>
          </slide>

          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer'
import {
  Hooper,
  Slide,
  Navigation as HooperNavigation
} from 'hooper'

export default {
  components: {
    Footer,
    Hooper,
    Slide,
    HooperNavigation
  },
  data () {
    return {
      themeClass: '--dark',
      blocks: [
        // 'page__transition-1',
        'page__transition-2'],
      offsets: [],
      windowHeight: 0,
      hooperSettings: {
        infiniteScroll: true,
        centerMode: false,
        wheelControl: false,
        breakpoints: {
          2400: {
            itemsToShow: 5
          },
          1200: {
            itemsToShow: 4
          },
          768: {
            itemsToShow: 3
          },
          475: {
            itemsToShow: 2
          },
          320: {
            itemsToShow: 1
          }
        }
      }
    }
  },
  methods: {
    windowHeightCounter () {
      this.windowHeight = window.innerHeight
    },
    scrollContentTransition () {
      let winScrollY = this.$parent.$data.topOffset + 0.65 * window.innerHeight
      let transitionEl

      for (let i = 0; i < this.offsets.length; i++) {
        if (winScrollY > this.offsets[i]) {
          transitionEl = document.querySelector(`.${this.blocks[i]}`)

          if (transitionEl) {
            transitionEl.classList.add('content--transition')
          }
        }
      }
    },
    sectionCollector () {
      this.offsets = []

      for (let i = 0; i < this.blocks.length; i++) {
        let el = document.querySelector(`.${this.blocks[i]}`)

        if (el) {
          let elOffset = el.offsetTop
          this.offsets.push(elOffset)
        }
      }
    }
  },
  beforeMount () {
    this.$emit('themeClass', {
      themeClass: '--dark'
    })
    this.$emit('navActiveButton', {
      navActiveButton: 'about'
    })
    this.$emit('headerBottomBorder', {
      headerBottomBorder: false
    })
  },
  mounted () {
    this.sectionCollector()

    window.addEventListener('scroll', () => {
      this.scrollContentTransition()
    })

    window.addEventListener('resize', () => {
      this.sectionCollector()
      this.scrollContentTransition()
    })
  },
  destroyed () {
    window.removeEventListener('scroll', () => {
      this.scrollContentTransition()
    })

    window.removeEventListener('resize', () => {
      this.sectionCollector()
      this.scrollContentTransition()
    })
  }
}
</script>
