import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/AboutUs.vue'
import Services from '../views/Services.vue'
import Projects from '../views/Projects.vue'
import Contact from '../views/Contact.vue'
import GardenmoenHotel from '../views/projects/gardermoen-park-inn-hotel.vue'
import ExtensionDevelopment from '../views/projects/mixed-use-refurbishment-and-extension-development.vue'
import HighEndHotel from '../views/projects/high-end-hotel-development-in-south-east-london.vue'
import LancasterCircus from '../views/projects/lancaster-circus.vue'
import FacadeDetailsRenders from '../views/projects/facade-details-renders.vue'
import LuxuryResidences from '../views/projects/luxury-residences-in-londons-center.vue'
import RvhMaternity from '../views/projects/rvh-maternity.vue'
import BatterseaExchange from '../views/projects/battersea-exchange-phase-2-block-a.vue'
import SaudiArabiaProjects from '../views/projects/qatar-oman-kuwait-saudi-arabia-projects.vue'
import NewPrefabricatedWallSystem from '../views/projects/new-prefabricated-wall-system-development.vue'
import ArthurStanleyHouseResidentialBlock from '../views/projects/arthur-stanley-house-residential-block.vue'
import ConsortPlaceMarketingSuite from '../views/projects/consort-place-marketing-suite.vue'
import BrooklineAvenue from '../views/projects/brookline-avenue'
import GradeListedBuilding from '../views/projects/grade-ii-listed-building-renovation'
import OneStationHill from '../views/projects/one-station-hill'
import LambStreetBishopsSquare from '../views/projects/lamb-street-bishops-square'
import ElephantPark from '../views/projects/elephant-park'
import ChristopherYard from '../views/projects/christopher-yard'
import Alloy from '../views/projects/alloy'
import MixedUseDevelopmentInLondon from '../views/projects/mixed-use-development-in-london'
import NationalRehabilitationCentre from '../views/projects/national-rehabilitation-centre'
import DiriyahGateDevelopment from '../views/projects/diriyah-gate-development'
import MountPleasantPhase from '../views/projects/mount-pleasant-phase-4'
import DataCenterRenovation from '../views/projects/data-center-renovation'
import TorontoMedicalUniversity from '../views/projects/toronto-medical-university'
import DwdPhase2 from '../views/projects/dwd-phase-2'
import NadHessa from '../views/projects/nad-hessa'
import Kamikind from '../views/projects/kamikind'
import IrishLife from '../views/projects/irish-life'
import WeirMill from '../views/projects/weir-mill'
import Britannia2b from '../views/projects/britannia-2b'
import MountPleasantPhase3 from '../views/projects/mount-pleasant-phase-3'

Vue.use(VueRouter)

const metaTags = [
  {
    name: 'description',
    content: 'Skytop Engineering is the team of high-level facade specialists. We offer our clients whole range of consultancy services required for the delivery of building skins at each key stage. We do everything that requires solid envelope engineering knowledge and practice.'
  },
  {
    property: 'og:description',
    content: 'Skytop Engineering is the team of high-level facade specialists. We offer our clients whole range of consultancy services required for the delivery of building skins at each key stage. We do everything that requires solid envelope engineering knowledge and practice.'
  },
  {
    property: 'keywords',
    content: 'Facade, design, engineering, cladding,  consultancy, curtain wall, shop drawings, drawings, rainscreen, envelopes, outsource'
  }
]

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Facade Design and Engineering - Skytop Engineering',
      metaTags: metaTags,
      gtm: 'My gtm Value'
    }
  },
  {
    path: '/about-us',
    name: 'aboutus',
    component: About,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue'),
    meta: {
      title: 'Skytop Engineering – About Us',
      metaTags: metaTags
    }
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
    meta: {
      title: 'Skytop Engineering – Projects',
      metaTags: metaTags
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: {
      title: 'Skytop Engineering – Projects',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/gardermoen-park-inn-hotel',
    name: 'gardermoen-park-inn-hotel',
    component: GardenmoenHotel,
    meta: {
      title: 'Gardermoen park inn hotel',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/mixed-use-refurbishment-and-extension-development',
    name: 'mixed-use-refurbishment-and-extension-development',
    component: ExtensionDevelopment,
    meta: {
      title: 'Mixed-use refurbishment and extension development',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/high-end-hotel-development-in-south-east-london',
    name: 'high-end-hotel-development-in-south-east-london',
    component: HighEndHotel,
    meta: {
      title: 'High-end hotel development in south east london',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/lancaster-circus',
    name: 'lancaster-circus',
    component: LancasterCircus,
    meta: {
      title: 'Lancaster circus',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/facade-details-renders',
    name: 'facade-details-renders',
    component: FacadeDetailsRenders,
    meta: {
      title: 'Facade details renders',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/luxury-residences-in-londons-center',
    name: 'luxury-residences-in-londons-center',
    component: LuxuryResidences,
    meta: {
      title: 'Luxury residences in London’s center',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/rvh-maternity',
    name: 'rvh-maternity',
    component: RvhMaternity,
    meta: {
      title: 'RVH Maternity',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/battersea-exchange-phase-2-block-a',
    name: 'battersea-exchange-phase-2-block-a',
    component: BatterseaExchange,
    meta: {
      title: 'Battersea exchange phase 2 block a',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/qatar-oman-kuwait-saudi-arabia-projects',
    name: 'qatar-oman-kuwait-saudi-arabia-projects',
    component: SaudiArabiaProjects,
    meta: {
      title: 'Qatar, Oman, Kuwait, Saudi Arabia projects',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/new-prefabricated-wall-system-development',
    name: 'new-prefabricated-wall-system-development',
    component: NewPrefabricatedWallSystem,
    meta: {
      title: 'New prefabricated wall system development',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/arthur-stanley-house-residential-block',
    name: 'arthur-stanley-house-residential-block',
    component: ArthurStanleyHouseResidentialBlock,
    meta: {
      title: 'Arthur Stanley House Residential Block',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/consort-place-marketing-suite',
    name: 'consort-place-marketing-suite',
    component: ConsortPlaceMarketingSuite,
    meta: {
      title: 'Consort Place Marketing Suite',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/201-brookline-avenue',
    name: '201-brookline-avenue',
    component: BrooklineAvenue,
    meta: {
      title: '201 Brookline Avenue',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/grade-ii-listed-building-renovation',
    name: 'grade-ii-listed-building-renovation',
    component: GradeListedBuilding,
    meta: {
      title: 'Grade II listed building renovation',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/one-station-hill',
    name: 'one-station-hill',
    component: OneStationHill,
    meta: {
      title: 'One Station Hill',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/lamb-street-bishops-square',
    name: 'lamb-street-bishops-square',
    component: LambStreetBishopsSquare,
    meta: {
      title: 'Lamb Street, Bishops Square',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/elephant-park',
    name: 'elephant-park',
    component: ElephantPark,
    meta: {
      title: 'Elephant Park',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/christopher-yard',
    name: 'christopher-yard',
    component: ChristopherYard,
    meta: {
      title: 'Christopher Yard',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/alloy',
    name: 'alloy',
    component: Alloy,
    meta: {
      title: 'Alloy',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/mixed-use-development-in-london-with-sustainable-approach',
    name: 'mixed-use-development',
    component: MixedUseDevelopmentInLondon,
    meta: {
      title: 'Mixed-use development in London with sustainable approach',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/national-rehabilitation-centre',
    name: 'national-rehabilitation-centre',
    component: NationalRehabilitationCentre,
    meta: {
      title: 'National Rehabilitation Centre',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/diriyah-gate-development',
    name: 'diriyah-gate-development',
    component: DiriyahGateDevelopment,
    meta: {
      title: 'Diriyah Gate Development Package 05 (Upper Luxury Escarpment Resort)',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/mount-pleasant-phase-4',
    name: 'mount-pleasant-phase-4',
    component: MountPleasantPhase,
    meta: {
      title: 'Mount Pleasant Phase 4',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/data-center-renovation',
    name: 'data-center-renovation',
    component: DataCenterRenovation,
    meta: {
      title: 'Data Center Renovation',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/toronto-medical-university',
    name: 'toronto-medical-university',
    component: TorontoMedicalUniversity,
    meta: {
      title: 'Toronto Medical Uiversity',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/dwd-phase-2',
    name: 'dwd-phase-2',
    component: DwdPhase2,
    meta: {
      title: 'Data Center Renovation',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/nad-hessa',
    name: 'nad-hessa',
    component: NadHessa,
    meta: {
      title: 'Nad Hessa',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/kamikind',
    name: 'kamikind',
    component: Kamikind,
    meta: {
      title: 'KAMIKIND (Silicone Oasis Warehouse)',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/irish-life',
    name: 'irish-life',
    component: IrishLife,
    meta: {
      title: 'Irish Life',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/weir-mill',
    name: 'weir-mill',
    component: WeirMill,
    meta: {
      title: 'Weir Mill',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/britannia-2b',
    name: 'britannia-2b',
    component: Britannia2b,
    meta: {
      title: 'Britannia 2b',
      metaTags: metaTags
    }
  },
  {
    path: '/projects/mount-pleasant-phase-3',
    name: 'mount-pleasant-phase-3',
    component: MountPleasantPhase3,
    meta: {
      title: 'Mount Pleasant phase 3',
      metaTags: metaTags
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    meta: {
      title: 'Skytop Engineering – Contacts',
      metaTags: metaTags
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: function (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      window.history.scrollRestoration = 'manual'

      if (savedPosition) {
        resolve(savedPosition)
      } else {
        resolve({ x: 0, y: 0 })
      }
    })
  },
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })

    .forEach(tag => document.head.appendChild(tag))

  next()
})

// router.afterEach((to, from, next) => {
// let checkboxEl = document.querySelector('.header__burger_toggle')
// let menuBgEl = document.querySelector('.header__burger_bg')
// let toggleButEl = document.querySelector('.header__burger_btn')
// if (checkboxEl) {
//   checkboxEl.checked = false
//   document.body.style.overflow = ''
//   if (menuBgEl) {
//     menuBgEl.classList.remove('header__burger_bg--active')
//   }
//   if (toggleButEl) {
//     toggleButEl.classList.remove('header__burger_btn--active')
//   }
// }
// })

export default router
