<template>
  <div class="projects-page">
    <div class="projects-page__container">
      <div class="page__transit-1 content--transition">
        <div class="universal__title">
          <div class="universal__title_back h1">{{ $t('projects.projectBack') }}</div>
          <div class="universal__title_main">
            <div class="universal__title_main-text h3">{{ $t('projects.projectTitle') }}</div>
            <div class="universal__title_main-subtext h3">{{ $t('projects.projectSubTitle') }}</div>
          </div>
        </div>

        <div class="universal__content">
          <div class="universal__content_col">
            <p class="h4">{{ $t('projects.projectText1') }}</p>
            <p>{{ $t('projects.projectText2') }}</p>
          </div>
        </div>
      </div>

      <div class="projects-page__projects">
        <section class="page__transit-2 content--transition">

          <div class="projects-full__blocks">
            <router-link to="/projects/toronto-medical-university" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr24Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/dwd-phase-2" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr25Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/nad-hessa" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr26Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/kamikind" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr27Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/irish-life" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr28Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/weir-mill" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr29Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/britannia-2b" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr30Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/mount-pleasant-phase-3" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr31Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <!-- OLD projects -->

            <router-link to="/projects/national-rehabilitation-centre" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr20Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/diriyah-gate-development" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr21Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/mount-pleasant-phase-4" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr22Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/data-center-renovation" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr23Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/mixed-use-development-in-london-with-sustainable-approach" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr19Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/alloy" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr18Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/grade-ii-listed-building-renovation" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr13Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/one-station-hill" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr14Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/lamb-street-bishops-square" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr15Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/elephant-park" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr16Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/christopher-yard" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr17Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/201-brookline-avenue" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr12Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/arthur-stanley-house-residential-block" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr10Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/consort-place-marketing-suite" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr11Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/gardermoen-park-inn-hotel" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr7Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/mixed-use-refurbishment-and-extension-development" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr5Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/high-end-hotel-development-in-south-east-london" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr4Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/lancaster-circus" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr3Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/facade-details-renders" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr8Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/luxury-residences-in-londons-center" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr6Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/rvh-maternity" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr2Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/battersea-exchange-phase-2-block-a" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr1Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/qatar-oman-kuwait-saudi-arabia-projects" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.mainTitle') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>

            <router-link to="/projects/new-prefabricated-wall-system-development" class="projects-full__blocks_block">
              <div class="projects-full__blocks_hover">
                  <p class="projects-full__blocks_hover-title">
                    {{ $t('projects.pr9Title') }}
                  </p>

                  <div class="text-img__content_nav projects-full__blocks_hover-link">
                    <div class="btn__nav-wrapper">
                      <div class="btn__nav-text">{{ $t('home.navButText') }}</div>
                      <div class="btn__nav-icon">
                        <svg width="56" height="8" viewBox="0 0 56 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
              </div>
            </router-link>
          </div>
        </section>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer'

export default {
  components: {
    Footer
  },
  props: {
    initialStatuses: {
      type: Array,
      default: function () {
        return [false, false, false]
      }
    }
  },
  data () {
    return {
      themeClass: '--dark',
      modalSlider: false,
      blocks: [
        'page__transit-2'
      ],
      offsets: [],
      windowHeight: 0,
      scrollPreloaderStatuses: [false, false, false],
      statusesCounter: 0,
      statuses: this.initialStatuses
    }
  },
  computed: {},
  methods: {
    scrollContentTransition () {
      let winScrollY = this.$parent.$data.topOffset + 0.65 * window.innerHeight
      let transitionEl

      for (let i = 0; i < this.offsets.length; i++) {
        if (winScrollY > this.offsets[i]) {
          transitionEl = document.querySelector(`.${this.blocks[i]}`)
          if (transitionEl) {
            transitionEl.classList.add('content--transition')
          }
        }
      }
    },
    sectionCollector () {
      this.offsets = []

      for (let j = 0; j < this.blocks.length; j++) {
        let el = document.querySelector(`.${this.blocks[j]}`)
        if (el) {
          let elOffset = el.offsetTop
          this.offsets.push(elOffset)
        }
      }
    },
    scrollContentRender () {
      let scrollBottom = this.$parent.$data.topOffset + window.innerHeight
      let pageHeight = document.body.scrollHeight

      if (scrollBottom > pageHeight - 50) {
        let newCounter = this.statusesCounter
        let changedStatusesArr = this.statuses
        let changedScrollPreloaderStatuses = this.scrollPreloaderStatuses

        if (newCounter < changedStatusesArr.length) {
          changedScrollPreloaderStatuses.splice(newCounter, 1, true)
          this.scrollPreloaderStatuses = changedScrollPreloaderStatuses
          setTimeout(() => {
            changedScrollPreloaderStatuses.splice(newCounter, 1, false)
            this.scrollPreloaderStatuses = changedScrollPreloaderStatuses

            changedStatusesArr.splice(newCounter, 1, true)
            this.statuses = changedStatusesArr
            this.statusesCounter = newCounter + 1

            setTimeout(() => {
              this.sectionCollector()
              this.scrollContentTransition()
            }, 500)
          }, 2000)
        }
      }
    }
  },
  beforeMount () {
    this.$emit('themeClass', {
      themeClass: '--dark'
    })
    this.$emit('navActiveButton', {
      navActiveButton: 'projects'
    })
    this.$emit('headerBottomBorder', {
      headerBottomBorder: false
    })
  },
  mounted () {
    this.sectionCollector()

    window.addEventListener('scroll', () => {
      this.scrollContentTransition()
      this.scrollContentRender()
    })

    window.addEventListener('resize', () => {
      this.sectionCollector()
      this.scrollContentTransition()
    })
  },
  destroyed () {
    window.removeEventListener('scroll', () => {
      this.scrollContentTransition()
      this.scrollContentRender()
    })

    window.removeEventListener('resize', () => {
      this.sectionCollector()
      this.scrollContentTransition()
    })
  }
}
</script>
